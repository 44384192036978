import appConfig from './appConfig';
import { setDefaultOptions, loadCss, loadModules } from 'esri-loader';

// set options before loading Esri JSAPI modules in any Svelte components
// and load Esri JSAPI CSS right away from CDN
setDefaultOptions({ version: appConfig.esriVersion });
loadCss(appConfig.esriVersion);

// configure the Esri Portal base URL
// https://developers.arcgis.com/javascript/latest/api-reference/esri-config.html
loadModules(['esri/config']).then(([esriConfig]) => {
  esriConfig.portalUrl = appConfig.portalUrl;
});

// Svelte entry point
import App from './App.svelte';

const app = new App({
  target: document.body,
  // props: {
  //   name: 'world'
  // }
});

export default app;
