<script>
  import {
    stateSelectionStore,
    nassRegionSelectionStore,
    exportFeaturesStore,
  } from "./stores";

  export let mapView;

  // initially hide the outer container until it is added as a UI element within the MapView
  // later it will toggle between 'flex' (export feature selected) or  'none' (no export feature)
  $: sliderOuterContainerDisplayValueCSS = $exportFeaturesStore
    ? "flex"
    : "none";
</script>

<div
  class="selection-toolbar-outer-container esri-widget esri-floating-box-shadow"
  style="display: {sliderOuterContainerDisplayValueCSS}"
>
  <button
    class="usa-button"
    style="margin-right: 4px;"
    on:click={() => {
      if (mapView.animation) {
        // if a panning/zooming animation is currently ongoing,
        // stop it immediately and then `goTo` our desired location
        mapView.animation
          .when()
          .catch(() => mapView.goTo($exportFeaturesStore));

        mapView.animation.stop();
      } else {
        // otherwise simply `goTo` our desired location
        mapView.goTo($exportFeaturesStore);
      }
    }}
  >
    <span
      class="margin-right-1 esri-icon-zoom-in-magnifying-glass custom-button-icon"
    />
    View selected area
  </button>

  <button
    class="usa-button"
    style="margin-left: 4px; margin-right: 0;"
    on:click={() => {
      // if there is a newly drawn polygon AOI, attempt to clean up the comboboxes in <SelectArea>
      // in case the user previously had chosen a state/county or nass region
      stateSelectionStore.set(undefined);
      nassRegionSelectionStore.set(undefined);

      exportFeaturesStore.set(false);
    }}
  >
    <span class="margin-right-1 esri-icon-trash custom-button-icon" />
    Clear selected area
  </button>
</div>

<style>
  .selection-toolbar-outer-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
  }

  button {
    font-size: 0.93rem;
  }

  button .custom-button-icon {
    vertical-align: top;
  }

  .selection-toolbar-outer-container .usa-button {
    margin-right: 4px;
    padding: 12px 20px;
    font-size: 16px !important;
    line-height: 1 !important;
    color: #565c65;
    background-color: #fff;
    border-radius: 0rem;
    font-weight: 400;
  }

  .usa-button:hover {
    color: #303030;
    background-color: #f3f3f3;
  }
</style>
