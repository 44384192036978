<script>
  import { onMount, onDestroy } from "svelte";
  import appConfig from "./appConfig";
  import { loadModules } from "esri-loader";
  import TimeSlider from "./TimeSlider.svelte";
  import SelectionToolbar from "./SelectionToolbar.svelte";
  import {
    mapViewStore,
    imageryLayerStore,
    sketchViewModelStore,
    statesListStore,
    stateSelectionStore,
    countiesListStore,
    countySelectionStore,
    nassRegionsListStore,
    nassRegionSelectionStore,
    allPixelCategoriesStore,
    cropsListStore,
    cropsSelectionStore,
    uploadedFeaturesStore,
    exportFeaturesStore,
    exportYearStore,
    layerListStore,
    layerSelectionStore,
    appMode,
    currentImageryLayer,
    datasetParam,
  } from "./stores";
  import {
    getLayerByTitle,
    queryForUniqueStates,
    queryForUniqueCountiesInAState,
    queryForUniqueNassRegions,
    cleanupJsapiListener,
    establishJSAPIMapViewWidgets,
    getCropsUniqueValuesRenderer,
    queryForAllLayers,
  } from "./utils";
  import { v4 as uuidv4 } from "uuid";

  // reference to the DOM node where the MapView will be created
  let mapViewContainer;
  // reference to the MapView instance that will be created during `onMount`
  let mapView;

  // later this layer will be used outside of svelte's `onMount`
  let statesFeatureLayer;

  // later this layer will be used outside of svelte's `onMount`
  // - will be queried whenever a state is selected
  let countiesFeatureLayer;

  // later this layer will be used outside of svelte's `onMount`
  // - will help with NASS regions selection UI
  let nassRegionsFeatureLayer;

  // client-side layer used to house the selected export feature graphic(s)
  let exportSelectionGraphicsLayer = null;

  // mask layer for fading things out
  let maskGraphicsLayer = null;

  let selectionToolbarContainer;
  let selectionToobarComponent;

  let timeSliderOuterContainer;
  let timeSliderComponent;
  let timeSliderVisible;
  let imageryLayerVisibilityWatcher;
  let exportYearStoreUnsubscribe;

  onMount(async () => {
    // load Esri JSAPI modules
    const [WebMap, MapView, GraphicsLayer] = await loadModules([
      "esri/WebMap",
      "esri/views/MapView",
      "esri/layers/GraphicsLayer",
    ]);

    // construct a MapView instance and a WebMap from a configured item id
    mapView = new MapView({
      container: mapViewContainer,
      map: new WebMap({
        portalItem: {
          id: appConfig.webmapId,
        },
      }),
      ui: {
        // keep the default zoom widget out of here
        // because it is noticeable when it is moved to another corner in `establishMapViewWidgets`
        components: ["attribution"],
      },
      constraints: {
        rotationEnabled: false,
        minZoom: 2,
      },
      // timeExtent { start, end } will default to the imagery layer's most recent year and will be updated by the user's slider interactions
      popup: {
        collapseEnabled: false,
        dockOptions: {
          buttonEnabled: false,
        },
      },
    });

    // startup standard JSAPI "out of the box" mapping widgets
    establishJSAPIMapViewWidgets(mapView);

    // compile and startup this Svelte component with JS rather than with HTML markup below
    // because we need to wait for the mapView instance to be availble to add it to its own UI
    selectionToobarComponent = new SelectionToolbar({
      target: selectionToolbarContainer,
      props: {
        mapView,
      },
    });

    mapView.ui.add(selectionToolbarContainer, "manual");

    // wait on the webmap to load before continuing with other startup logic
    await mapView.map.when();
    var layers = [];
    for (const [key, value] of Object.entries(appConfig.LayerDatasetNames)) {
      layers.push(value.layerTitle);
    }
    layerListStore.set(layers);

    // bring in the initial extent a bit from the webmap's default 48 states CONUS
    mapView.goTo(
      mapView.map.initialViewProperties.viewpoint.targetGeometry.expand(-0.9)
    );

    // get access to layer instances by looking them up in the webmap's layers

    // 1. CDL imagery layer
    currentImageryLayer.set(getLayerByTitle(mapView.map, $layerSelectionStore));

    // compile and startup this Svelte component with JS rather than with HTML markup below
    // because we need to wait for the mapView instance to be available to add it to its own UI

    timeSliderComponent = new TimeSlider({
      target: timeSliderOuterContainer,
      props: {
        mapView,
        timeEnabledImageryLayer: $currentImageryLayer,
      },
    });

    mapView.ui.add(timeSliderOuterContainer, "manual");

    // toggle display of the time slider widget outer container by having it be tied directly to the imagery layer's visibility
    // the `timeSliderVisible` variable will drive the conditional CSS class that is used in the HTML markup
    const imageryLayerVisibilityChanged = (layerVisibleValue) => {
      if ($currentImageryLayer.visible == true) {
        calculatePixelInfo();
      }
      if ($datasetParam == "CDLS_WM") {
        timeSliderVisible = layerVisibleValue;
      } else {
        timeSliderVisible = false;
      }
    };

    imageryLayerVisibilityChanged($currentImageryLayer.visible);
    imageryLayerVisibilityWatcher = $currentImageryLayer.watch(
      "visible",
      imageryLayerVisibilityChanged
    );

    // wait until the imagery layer's `loaded` property is `true` before attempting to
    // gain access to its categorical crop values `attributeTable`
    const imageryLayerLoadedWatcher = $currentImageryLayer.watch(
      "loaded",
      (loadedValue) => {
        if (!loadedValue) {
          return;
        }
        // remove this `loaded` property watcher to make the following logic only run one time during startup
        cleanupJsapiListener(imageryLayerLoadedWatcher);
        // new in JSAPI 4.17, we can set a bounding constraint extent on the MapView
        // and set it to be the same as the extent of the imagery layer
        mapView.constraints.geometry = $currentImageryLayer.fullExtent.clone();
        currentImageryLayer.set(
          getLayerByTitle(mapView.map, $layerSelectionStore)
        );
        const allPixelCategories =
          $currentImageryLayer.serviceRasterInfo.attributeTable.features.map(
            (f) => {
              const r = f.attributes.Red;
              const g = f.attributes.Green;
              const b = f.attributes.Blue;
              const a = "1";
              return {
                // properties to help with the the crop selection UI/UX
                value: f.attributes.Value,
                displayText: f.attributes.Class_Names, // used by both the UI and an export GP param
                colorSwatch: `rgba(${r}, ${g}, ${b}, ${a})`,

                // property to help with pixelFilter masking (DEPRECATED)
                rgb: [r, g, b],

                // and all other service attributes just in case they are needed later
                serviceAttributes: f.attributes,
              };
            }
          );

        // set the full list of crop categories on the svelte store,
        // which will subsequently set the svelte "derived store" `cropsListStore`,
        // which will in turn drive the UI/UX combobox and the imagery layer's renderer
        allPixelCategoriesStore.set(allPixelCategories);

        // set the imagery layer's initial renderer to show all possible categories
        // this will update the server-side rendering rule "Colormap" raster function
        $currentImageryLayer.renderer =
          getCropsUniqueValuesRenderer($cropsListStore);

        // establish a popupTemplate
        $currentImageryLayer.popupEnabled = true;
        $currentImageryLayer.popupTemplate = {
          title: function () {
            return `Cropland Data Layer (CDL ${$exportYearStore})`;
          },
          content: function (e) {
            // look it up against our custom formatted raster attribute table info in `allPixelCategories` for attribute consistency
            // we found on occasion that raw attribute field names and values may change depending on how the ImageServer layer was published
            const matchingPixelInfo = allPixelCategories.find(
              (c) => c.value === e.graphic.attributes["Raster.Value"]
            );
            return `<p class="cdl-popup-info-row">
              <span class="cdl-popup-info-row-child">Crop</span>
              <span class="cdl-popup-info-row-child cdl-popup-info-row-child-value">
                <span class="color-swatch" style="background-color: ${matchingPixelInfo.colorSwatch};"></span>
                ${matchingPixelInfo.displayText}
              </span>
            </p>
            <p class="cdl-popup-info-row">
              <span class="cdl-popup-info-row-child">Pixel Value</span>
              <span class="cdl-popup-info-row-child cdl-popup-info-row-child-value">${matchingPixelInfo.value}</span>
            </p>`;
          },
          // remove the default "Zoom to" action (which zooms to the extent of the available raster sources)
          overwriteActions: true,
        };

        // auto-close the popup when the year changes
        exportYearStoreUnsubscribe = exportYearStore.subscribe(() =>
          mapView.popup.close()
        );

        // set the instance of the CDL ImageryLayer on a store so that other components can use it (<StatsDialog>)
        imageryLayerStore.set($currentImageryLayer);
      }
    );

    // 2. US states selection layer
    statesFeatureLayer = getLayerByTitle(
      mapView.map,
      appConfig.statesSelectionLayerTitle
    );
    // query for unique states to fill in the states drop down UI combobox
    queryForUniqueStates(
      statesFeatureLayer,
      appConfig.stateNameAttribute,
      appConfig.stateAbbreviationAttribute
    ).then((uniqueStateNames) => {
      statesListStore.set(uniqueStateNames);
    });

    // 3. counties selection layer
    // later this layer will be queried whenever a state is selected
    countiesFeatureLayer = getLayerByTitle(
      mapView.map,
      appConfig.countiesSelectionLayerTitle
    );

    // 4. NASS regions selection layer
    nassRegionsFeatureLayer = getLayerByTitle(
      mapView.map,
      appConfig.nassRegionsLayerTitle
    );
    // query for unique NASS regions to fill in the NASS regions drop down UI combobox
    queryForUniqueNassRegions(
      nassRegionsFeatureLayer,
      appConfig.nassRegionNameAttribute
    ).then((uniqueNassRegions) => {
      nassRegionsListStore.set(uniqueNassRegions);
    });

    // 5. add a client-side graphics layer which is used to house the selected export feature graphic
    exportSelectionGraphicsLayer = new GraphicsLayer({
      listMode: "hide",
    });
    mapView.map.layers.add(exportSelectionGraphicsLayer);

    // update the graphics layer that displays the user's export feature graphic
    // to cover the small chance that the user already chose a state or county while the MapView and webmap were starting up
    manageExportSelectionGraphics($exportFeaturesStore);
    // set up the sketchViewModel which depends on this graphicsLayer
    // it provides user drawing functionality that <HeaderToolbar>'s <DefineArea> can initiate
    establishSketchWidget(mapView, exportSelectionGraphicsLayer);

    // 7. most of the MapView's myriad startup steps are done,
    // set the instance of it on a store so that other components can use it
    // such as the <Search> component which controls a JSAPI Search widget
    mapViewStore.set(mapView);
  });

  let sketchViewModel;
  let sketchViewModelStateWatcher;
  let sketchViewModelCreateListener;
  async function establishSketchWidget(mapView, exportSelectionGraphicsLayer) {
    const [SketchViewModel] = await loadModules([
      "esri/widgets/Sketch/SketchViewModel",
    ]);

    // the SketchViewModel's user drawing mechanisms will be used by header buttons outside of the MapView's own UI
    sketchViewModel = new SketchViewModel({
      view: mapView,
      layer: exportSelectionGraphicsLayer,
      updateOnGraphicClick: false,
    });

    // override drawing session symbols to match the configured AOI graphic symbol
    sketchViewModel.polygonSymbol = appConfig.exportSelectionSymbol;
    sketchViewModel.polylineSymbol = appConfig.exportSelectionSymbol.outline;

    // we add a JSAPI watch to help Svelte understand when the sketchViewModel's `state` property changes
    // this `state` property helps to disable/enable the drawing buttons in <HeaderToolbar>
    // (this is wired up here to manage `onDestroy` JSAPI watchers/listeners cleanup in one place)
    sketchViewModelStateWatcher = sketchViewModel.watch("state", () => {
      sketchViewModelStore.update((svm) => svm);
    });

    // listen to the create:complete event to select for export the newly drawn graphic
    // and subsequently add it to the `exportSelectionGraphicsLayer`
    sketchViewModelCreateListener = sketchViewModel.on("create", (event) => {
      if (event.state !== "complete") {
        // do nothing until the create event state is truly complete
        return;
      }

      // add a unique id used by the stats and export GP tools for backend caching of results
      event.graphic.attributes = {
        _gpCacheKey: uuidv4(),
        _origin: `SKETCH ${event.tool}`,
        ...event.graphic.attributes,
      };

      // select the drawn graphic for export
      // which will update the exportFeaturesStore
      // and will subsequently manage the `exportSelectionGraphicsLayer`
      selectExportFeatures(mapView, [event.graphic], false);
    });

    // set the instance of the SketchViewModel on a store so that other components can use it (<DefineArea>)
    sketchViewModelStore.set(sketchViewModel);
  }

  // react to changes in the currently selected US state
  $: if ($stateSelectionStore) {
    stateChosen($stateSelectionStore);
  } else {
    stateCleared();
  }

  $: if ($layerSelectionStore) {
    if (mapView) {
      var layer = getLayerByTitle(mapView.map, $layerSelectionStore);
      currentImageryLayer.set(
        getLayerByTitle(mapView.map, $layerSelectionStore)
      );
      for (const [key, value] of Object.entries(appConfig.LayerDatasetNames)) {
        if (value.layerTitle == $layerSelectionStore.value) {
          datasetParam.set(value.datasetName);
        }
      }

      if ($layerSelectionStore.value == "Cropland Data Layer (CDL)") {
        appMode.set("CDL");
      } else {
        appMode.set("other");
      }

      for (let i = 0; i < mapView.map.layers.items.length; i++) {
        if (mapView.map.layers.items[i].title == $layerSelectionStore.value) {
          mapView.map.layers.items[i].visible = true;
        } else {
          mapView.map.layers.items[i].visible = false;
        }
      }
    }
  }

  async function stateChosen(stateSelection) {
    // clear away the NASS region when a new state is chosen
    nassRegionSelectionStore.set(undefined);

    // select the US state user choice
    // query for unique counties in the US state
    // and update the counties list store and county selection stores, to allow the user to optionally choose a child county
    await queryAndSelectExportFeature(
      mapView,
      statesFeatureLayer,
      `${appConfig.stateNameAttribute} = '${stateSelection.value}'`,
      appConfig.stateGPCacheKeyAttribute
    );

    if (stateSelection.value === "District of Columbia") {
      // but don't do a unique counties lookup for DC, as it should only exist at the state level
      // (but the counties feature layer we use also includes it)
      countiesListStore.set([]);
      countySelectionStore.set(undefined);
      return;
    }

    const uniqueCountyNames = await queryForUniqueCountiesInAState(
      countiesFeatureLayer,
      stateSelection.value,
      appConfig.countyStateNameAttribute,
      appConfig.countyNameAttribute
    );

    countiesListStore.set(uniqueCountyNames);
    countySelectionStore.set(undefined);
  }

  function stateCleared() {
    // if no state is chosen (or cleared after a selection):
    // - clear the county selection combobox choices
    // - clear any specific county combobox selection
    // - clear any user export selection features since no state (at a minimum) was chosen
    countiesListStore.set([]);
    countySelectionStore.set(undefined);
    exportFeaturesStore.set(false);
  }

  // react to changes in the currently selected US county
  $: if ($countySelectionStore) {
    countyChosen($countySelectionStore);
  } else {
    countyCleared();
  }

  function countyChosen(countySelection) {
    // select the child county user choice
    queryAndSelectExportFeature(
      mapView,
      countiesFeatureLayer,
      `${appConfig.countyNameAttribute} = '${countySelection.value}' AND ${appConfig.countyStateNameAttribute} = '${$stateSelectionStore.value}'`,
      appConfig.countyGPCacheKeyAttribute
    );
  }

  function countyCleared() {
    if ($stateSelectionStore) {
      // go back "up" to the parent US state and select it for export on behalf of the user
      queryAndSelectExportFeature(
        mapView,
        statesFeatureLayer,
        `${appConfig.stateNameAttribute} = '${$stateSelectionStore.value}'`,
        appConfig.stateGPCacheKeyAttribute
      );
    }
  }

  // react to changes in the currently selected NASS region
  $: if ($nassRegionSelectionStore) {
    nassRegionChosen($nassRegionSelectionStore);
  } else {
    nassRegionCleared();
  }

  function nassRegionChosen(nassRegionSelection) {
    stateSelectionStore.set(undefined);
    countiesListStore.set([]);
    countySelectionStore.set(undefined);

    // select the NASS region user choice
    queryAndSelectExportFeature(
      mapView,
      nassRegionsFeatureLayer,
      `${appConfig.nassRegionNameAttribute} = '${nassRegionSelection.value}'`,
      appConfig.nassRegionGPCacheKeyAttribute
    );
  }

  function nassRegionCleared() {
    exportFeaturesStore.set(false);
  }

  async function queryAndSelectExportFeature(
    mapView,
    featureLayer,
    whereClause,
    gpCacheKeyAttributeName,
    autoNavigate = true
  ) {
    if (!mapView || !featureLayer || !whereClause) {
      return;
    }

    const query = featureLayer.createQuery();
    query.where = whereClause;
    query.outFields = [featureLayer.objectIdField, gpCacheKeyAttributeName];

    try {
      const results = await featureLayer.queryFeatures(query);

      // TODO: deal with the case that there are NO valid features returned by the query

      // add a unique id used by the stats and export GP tools for backend caching of results
      results.features.forEach((feature) => {
        feature.attributes._gpCacheKey =
          feature.attributes[gpCacheKeyAttributeName];
        feature.attributes._origin = `FEATURE LAYER ${featureLayer.title}`;
      });

      // select the result features as the user's export selection (in most cases there should only be 1 feature)
      return selectExportFeatures(mapView, results.features, autoNavigate);
    } catch (error) {
      console.error(error);
    }
  }

  // this is the main entrypoint function to choose and manage an export AOI via several UI/UX methods:
  // - state combobox
  // - county combobox
  // - sketch drawing tools (see also <HeaderToolbar>)
  // - zipped shapefile upload (see also <HeaderToolbar>)
  async function selectExportFeatures(mapView, features, autoNavigate = true) {
    const exportFeatures = features.map((f) => f.clone());
    exportFeaturesStore.set(exportFeatures);

    if (autoNavigate) {
      // navigate the MapView
      return mapView.goTo(exportFeatures);
    } else {
      return Promise.resolve();
    }
  }

  // react to changes in the user's export store choices
  const exportFeaturesStoreUnsubscribe = exportFeaturesStore.subscribe(
    async (exportFeatures) => {
      const [GraphicsLayer, Polygon, Graphic, projection, geometryEngine] =
        await loadModules([
          "esri/layers/GraphicsLayer",
          "esri/geometry/Polygon",
          "esri/Graphic",
          "esri/geometry/projection",
          "esri/geometry/geometryEngine",
        ]);

      if (mapView && exportFeatures?.length > 0) {
        // change feature mask around AOI
        if (!maskGraphicsLayer) {
          maskGraphicsLayer = new GraphicsLayer({
            listMode: "hide",
          });
          mapView.map.layers.add(maskGraphicsLayer);
        }

        maskGraphicsLayer.removeAll();
        const geometry = new Polygon(appConfig.maskGeometry);
        const clippingGeometries = exportFeatures.map((feature) =>
          projection.project(feature.geometry, { wkid: 4326 })
        );
        let clippedGeometry = clippingGeometries.reduce((prev, curr) => {
          return geometryEngine.difference(prev, curr);
        }, geometry);
        const graphic = new Graphic({
          geometry: clippedGeometry,
          symbol: appConfig.maskSymbol,
        });
        maskGraphicsLayer.visible = true;
        maskGraphicsLayer.add(graphic);
      }

      // update the graphics layer that displays the user's export feature graphic
      manageExportSelectionGraphics(exportFeatures);

      // in case the user is in the middle of a drawing session,
      // but decides to make a different UI/UX change to the current selection feature
      // (such as choosing from the US states combobox),
      // then cancel the sketch drawing session
      if (sketchViewModel && sketchViewModel.state === "active") {
        sketchViewModel.cancel();
      }
    }
  );

  async function manageExportSelectionGraphics(exportSelectionFeatures) {
    if (!exportSelectionGraphicsLayer) {
      // stop early if this layer isn't available yet
      return;
    }

    // clean out previous export selection graphics
    exportSelectionGraphicsLayer.graphics.removeAll();

    // and add the new selection feature graphics to the layer, if present
    if (exportSelectionFeatures.length) {
      // apply the configured selection symbol to each
      exportSelectionFeatures.forEach((f) => {
        f.symbol = appConfig.exportSelectionSymbol;
      });

      exportSelectionGraphicsLayer.graphics.addMany(exportSelectionFeatures);
    }
  }

  // react to changes in the currently selected crop categories
  const cropsSelectionStoreUnsubscribe = cropsSelectionStore.subscribe((s) => {
    // update the array of `uniqueValueInfos` in the imagery layer's renderer when there are store changes
    // this will update the server-side rendering rule "Colormap" raster function
    if ($currentImageryLayer) {
      let newRenderer;
      if (s.length) {
        // show only those categories selected by the user
        newRenderer = getCropsUniqueValuesRenderer(s);
      } else {
        // show all possible categories
        newRenderer = getCropsUniqueValuesRenderer($cropsListStore);
      }
      $currentImageryLayer.renderer = newRenderer;
    }
  });

  // react to changes in the uploaded features store
  const uploadedFeaturesStoreUnsubscribe = uploadedFeaturesStore.subscribe(
    (uploadedFeatures) => {
      if (mapView && uploadedFeatures) {
        // add a unique id used by the stats and export GP tools for backend caching of results
        const sharedGPCacheKey = uuidv4();
        uploadedFeatures.forEach((uploadedFeature) => {
          uploadedFeature.attributes = {
            _gpCacheKey: sharedGPCacheKey,
            _origin: "UPLOADED",
            ...uploadedFeature.attributes,
          };
        });

        selectExportFeatures(mapView, uploadedFeatures, true);
      }
    }
  );

  function calculatePixelInfo() {
    if (mapView && $currentImageryLayer) {
      if ($currentImageryLayer.url) {
        // wait until the imagery layer's `loaded` property is `true` before attempting to
        // gain access to its categorical crop values `attributeTable`
        if ($currentImageryLayer.serviceRasterInfo) {
          const allPixelCategories =
            $currentImageryLayer.serviceRasterInfo.attributeTable.features.map(
              (f) => {
                const r = f.attributes.Red;
                const g = f.attributes.Green;
                const b = f.attributes.Blue;
                const a = "1";
                return {
                  // properties to help with the the crop selection UI/UX
                  value: f.attributes.Value,
                  displayText: f.attributes.Class_Names, // used by both the UI and an export GP param
                  colorSwatch: `rgba(${r}, ${g}, ${b}, ${a})`,

                  // property to help with pixelFilter masking (DEPRECATED)
                  rgb: [r, g, b],

                  // and all other service attributes just in case they are needed later
                  serviceAttributes: f.attributes,
                };
              }
            );
          allPixelCategoriesStore.set(allPixelCategories);
        }
      }
    }
  }

  $: $currentImageryLayer, calculatePixelInfo();

  onDestroy(() => {
    exportFeaturesStoreUnsubscribe();
    cropsSelectionStoreUnsubscribe();
    uploadedFeaturesStoreUnsubscribe();

    if (exportYearStoreUnsubscribe) {
      // this one was wired up during the map startup and CDL layer loading logic
      // let's be more careful when we try to unsubscribe from it
      exportYearStoreUnsubscribe();
    }

    // destroy any components that were compiled in the
    // less common "client-side component API" with JS
    if (selectionToobarComponent) {
      selectionToobarComponent.$destroy();
    }

    if (timeSliderComponent) {
      timeSliderComponent.$destroy();
    }

    // and remove Esri JSAPI listeners
    cleanupJsapiListener(sketchViewModelStateWatcher);
    cleanupJsapiListener(sketchViewModelCreateListener);
    cleanupJsapiListener(imageryLayerVisibilityWatcher);
  });
</script>

<div class="mapview-container" bind:this={mapViewContainer} />

<!-- this will be added as a widget once the MapView starts up -->
<div
  class="selection-toolbar-container desktop-view-title"
  bind:this={selectionToolbarContainer}
/>

<!-- this will be added as a widget once the MapView starts up -->
<div
  class="time-slider-container"
  class:invisible={!timeSliderVisible}
  bind:this={timeSliderOuterContainer}
/>

<!-- this will be added as a widget once the MapView starts up -->
<style>
  .mapview-container {
    width: 100%;
    height: 100%;
  }

  .selection-toolbar-container {
    left: 60px;
    top: 15px;
    /* modify z-index so this doesn't obscure other widgets */
    z-index: -1;
  }

  .time-slider-container {
    left: calc(50% - 230px);
    bottom: 30px;
    /* modify z-index so this doesn't obscure other widgets */
    z-index: 1;
    /* tie an opacity transition to the imagery layer's own `visibility` property */
    transition: opacity 200ms linear;
  }

  .time-slider-container.invisible {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  :global(
      .esri-view-width-xlarge .esri-popup__main-container,
      .esri-view-width-large .esri-popup__main-container
    ) {
    /* override popup width at xlarge and large breakpoints to be the same as the medium breakpoint */
    width: 340px !important;
  }

  :global(.esri-popup__header) {
    background-color: rgba(237, 237, 237, 1);
    align-items: center !important;
  }

  :global(.esri-popup__header-title) {
    font-size: 16px !important;
  }

  :global(.cdl-popup-info-row) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* override some rules in `.esri-feature-content p` */
    font-size: 16px !important;
    margin: 12px 0 0 !important;
  }

  :global(.cdl-popup-info-row .cdl-popup-info-row-child) {
    flex: 0 0 100px;
  }

  :global(
      .cdl-popup-info-row
        .cdl-popup-info-row-child.cdl-popup-info-row-child-value
    ) {
    flex: 1 1 auto;
    font-weight: bold;
  }
</style>
