<script>
  import { fade } from "svelte/transition";

  export let exportJobType = null; // null, 'exportAOI', 'print'
  export let exportJobStatus = null; // null, 'processing', 'success', 'failure'
  export let exportJobInfo = null; // Esri JSAPI GP Job info object

  function clearInfoToCloseAlert() {
    exportJobType = null;
    exportJobStatus = null;
    exportJobInfo = null;
  }
</script>

<div>
  {#if exportJobStatus === "processing"}
    <!-- height: 48px to be the same as the other left-hand side floating tools -->
    <div
      class="usa-alert usa-alert--info usa-alert--slim custom-updating"
      style="height: 48px;"
    >
      <div class="usa-alert__body">
        <p class="usa-alert__text">Processing PDF for export</p>
      </div>
    </div>
  {:else if exportJobStatus === "success"}
    <div class="usa-alert usa-alert--success" out:fade>
      <div class="usa-alert__body">
        <div class="alert-title-wrapper">
          <h3 class="usa-alert__heading margin-bottom-2">
            PDF ready for download
          </h3>

          <!-- custom close button, wrapped up in a div with the alert title text -->
          <button
            class="usa-button usa-button--unstyled margin-left-4 success-alert-close-button"
            title="Close"
            on:click={() => {
              clearInfoToCloseAlert();
            }}
          >
            <span class="esri-icon-close" style="vertical-align: top;" />
          </button>
        </div>

        <p class="usa-alert__text">
          <a
            class="usa-link"
            href={exportJobInfo.resultData.value.url}
            download
            target="_blank"
          >
            <span
              class="esri-icon-download margin-right-1"
              style="vertical-align:middle;"
            />Download PDF
          </a>
        </p>
      </div>
    </div>
  {:else if exportJobStatus === "failure"}
    <div class="usa-alert usa-alert--error usa-alert--slim" out:fade>
      <div class="usa-alert__body">
        <p class="usa-alert__text">
          An error occurred while exporting

          <!-- custom close button, inline with the slim style alert's body text -->
          <button
            class="usa-button usa-button--unstyled margin-left-4 error-alert-close-button"
            title="Close"
            on:click={() => {
              clearInfoToCloseAlert();
            }}
          >
            <span class="esri-icon-close" style="vertical-align: top;" />
          </button>
        </p>
      </div>
    </div>
  {/if}
</div>

<style>
  /* to aid in aligning the title text and our custom close button */
  .alert-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .success-alert-close-button {
    position: relative;
    top: -6px;
    background: transparent;
    color: #1b1b1b;
  }

  .error-alert-close-button {
    background: transparent;
    color: #1b1b1b;
  }
</style>
