<script>
  import { onMount } from "svelte";
  import Tabulator from "tabulator-tables";

  export let values = [];

  let tableNode;
  let table;

  onMount(() => {
    table = new Tabulator(tableNode, {
      layout: "fitColumns",
      height: "calc(80vh - 200px)", // a rough approximation considering the other elements in the dialog and tab child (see also the chart code)
      // maxHeight: 'calc(80vh - 200px)', // we can experiment more with this property instead of height, if we want the bottom totals row to not be pulled down
      selectable: false,
      data: values,
      columns: [
        {
          title: "Value",
          field: "value",
          sorter: "number",
          hozAlign: "right",
          maxWidth: 70,
          // bottom footer row of the table that shows the result of a calculation (here we use it to show static text)
          bottomCalc: () => "Total",
        },
        {
          title: "Category",
          field: "displayText",
          formatter: (cell, formatterParams, onRendered) => {
            // cell - the cell component
            // formatterParams - parameters set for the column
            // onRendered - function to call when the formatter has been rendered

            // return custom HTML with a "color swatch"
            const cellData = cell.getData();
            return `<span class="color-swatch" style="background-color: ${cellData.colorSwatch};"></span>${cellData.displayText}`;
          },
          sorter: "string",
          widthGrow: 1.75,
          // bottom footer row of the table that shows the result of a calculation
          bottomCalc: "count",
        },
        {
          title: "Pixel count",
          field: "categoryCount",
          sorter: "number",
          sorterParams: {
            thousandSeperator: ",",
          },
          // return a string with a language-sensitive representation of this number
          // intent is to have a comma thousands separator in "en-US"
          formatter: (cell) => cell.getValue().toLocaleString(),
          hozAlign: "right",
          // bottom footer row of the table that shows the result of a calculation
          bottomCalc: "sum",
          bottomCalcFormatter: (cell) => cell.getValue().toLocaleString(),
        },
        {
          title: "Area (acres)",
          field: "area",
          sorter: "number",
          sorterParams: {
            thousandSeperator: ",",
          },
          // return a string with a language-sensitive representation of this number
          // intent is to have a comma thousands separator in "en-US"
          formatter: (cell) => cell.getValue().toLocaleString(),
          hozAlign: "right",
          // bottom footer row of the table that shows the result of a calculation
          bottomCalc: "sum",
          bottomCalcFormatter: (cell) => cell.getValue().toLocaleString(),
        },
        {
          title: "Agricultural",
          field: "ag",
          sorter: "boolean",
          visible: false,
        }
      ],
      initialSort: [
        {
          column: "categoryCount",
          dir: "desc",
        },
        {
          column: "area",
          dir: "desc",
        },
        {
          column: "ag",
          dir: "desc",
        },
      ],
    });
  });

  function downloadTable() {
    if (table) {
      table.download("csv", "CroplandCROS_AOI_Data.csv");
    }
  }
</script>

<button class="usa-button" on:click={downloadTable}>Download data</button>

<div bind:this={tableNode} class="table-container" />

<style>
  .table-container {
    border: 1px solid #ddd;
  }

</style>
