<script>
  import { exportFeaturesStore, exportYearStore } from "../stores";

  import ExportAOIDialog from "./exportTools/ExportAOIDialog.svelte";
  import PrintDialog from "./exportTools/PrintDialog.svelte";

  export let expanded;
  export let collapseAllCustomDropdowns;
  export let exportJobType = null; // null, 'exportAOI', 'print'
  export let exportJobStatus = null; // null, 'processing', 'success', 'failure'
  export let exportJobInfo = null;

  // disable the export button if there is no polygon AOI or year selection
  // or if there is an existing export job processing
  $: disabledExportAOIButton =
    exportJobStatus === "processing" ||
    !$exportFeaturesStore ||
    !$exportYearStore;
  $: exportTooltip =
    exportJobStatus === "processing"
      ? "Please wait until the current export task is finished."
      : !$exportFeaturesStore
      ? "Please select or define an area."
      : !$exportYearStore
      ? "Please choose a year."
      : "";

  $: disabledPrintButton = exportJobStatus === "processing";
  $: printTooltip =
    exportJobStatus === "processing"
      ? "Please wait until the current export task is finished."
      : "";

  // variables and functions to handle modal opening and closing
  let exportAOIDialogOpen = false;
  function openExportAOIDialog() {
    exportAOIDialogOpen = true;
  }
  function closeExportAOIDialog() {
    exportAOIDialogOpen = false;
  }

  let printDialogOpen = false;
  function openPrintDialog() {
    printDialogOpen = true;
  }
  function closePrintDialog() {
    printDialogOpen = false;
  }
</script>

<!-- USWDS nav bar child drop down menu -->
<!-- to get the child contents to be left-justified when opened, we have to apply `position: relative;` to the <li> and then `right: 0; position: absolute;` to the child <form> -->
<li class="usa-nav__primary-item collapsible desktop-view-title">
  <button
    class="usa-accordion__button usa-current"
    aria-expanded={expanded}
    on:click={() => {
      if (expanded) {
        expanded = false;
      } else {
        collapseAllCustomDropdowns();
        expanded = true;
      }
    }}
  >
    <span>Export</span>
  </button>
  <form
    class="usa-form usa-nav__submenu esri-floating-box-shadow action-tool-dropdown"
    style="right: 0; position: absolute"
    hidden={!expanded}
  >
    <div class="usa-form-group" title={exportTooltip}>
      <button
        class="usa-button usa-button--outline width-full"
        disabled={disabledExportAOIButton}
        on:click|preventDefault={() => {
          openExportAOIDialog();
          expanded = false;
        }}
      >
        <img
          src="images/file-data-16.png"
          alt="Export selected area icon"
          height="16"
          class="margin-right-1 custom-esri-button-icon"
        />
        Export selected area
      </button>
    </div>

    <div class="usa-form-group" title={printTooltip}>
      <button
        class="usa-button usa-button--outline width-full"
        disabled={disabledPrintButton}
        on:click|preventDefault={() => {
          openPrintDialog();
          expanded = false;
        }}
      >
        <img
          src="images/file-pdf-16.png"
          alt="Export selected area icon"
          height="16"
          class="margin-right-1 custom-esri-button-icon"
        />
        Save map as PDF
      </button>
    </div>
  </form>
</li>

<!-- dialog modal that can be opened by the export area button -->
<ExportAOIDialog
  isOpen={exportAOIDialogOpen}
  close={closeExportAOIDialog}
  bind:exportJobType
  bind:exportJobStatus
  bind:exportJobInfo
/>

<!-- dialog modal that can be opened by the save/print map button -->
<PrintDialog
  isOpen={printDialogOpen}
  close={closePrintDialog}
  bind:exportJobType
  bind:exportJobStatus
  bind:exportJobInfo
/>

<style>
  /* img within a button needs some help appearing to be disabled, too */
  button:disabled img {
    opacity: 0.3;
  }
</style>
