<script>
  import { DialogOverlay, DialogContent } from "svelte-accessible-dialog";
  import Select from "svelte-select";
  import { getPrintLayoutTemplates, handlePrintAction } from "../../utils";
  import appConfig from "../../appConfig";
  import { mapViewStore, printCutoutMaskingFeatureStore } from "../../stores";

  export let isOpen;
  export let close;
  export let exportJobType = null; // null, 'exportAOI', 'print'

  export let exportJobStatus = null; // null, 'processing', 'success', 'failure'
  export let exportJobInfo = null;

  let layoutTemplateOptions;
  let layout;
  getPrintLayoutTemplates().then((layoutTemplates) => {
    layoutTemplateOptions = layoutTemplates;
    layout = layoutTemplateOptions[0];
  });
  let printQualityDpi = 96;
  let exportProjectionWKID = appConfig.exportProjectionOptions[0];
  let scalePreserved = true;
  let title;
  let producedBy;
  let description;

  // wrapper around async print web map util
  async function beginPrint(
    mapView,
    layout,
    printQualityDpi,
    exportProjectionWKID,
    scalePreserved,
    title,
    producedBy,
    description
  ) {
    exportJobType = "print";
    exportJobStatus = null;
    exportJobInfo = null;

    try {
      exportJobStatus = "processing";

      const jobCompleteInfo = await handlePrintAction(
        mapView,
        layout.value,
        printQualityDpi,
        exportProjectionWKID.value,
        scalePreserved,
        title,
        producedBy,
        description,

        // OPTIONAL: mapView has been processed by the print params
        // and now it is **likely safe** to clear any cookie cutter graphic
        cleanupAndClose
      );

      exportJobStatus = "success";
      exportJobInfo = jobCompleteInfo;
    } catch (error) {
      exportJobStatus = "failure";
      exportJobInfo = error;
    }
  }

  let cookieCutterChecked = false;

  // react to changes in cookieCutterChecked
  $: togglePrintCutoutMaskingFeature(cookieCutterChecked);

  function togglePrintCutoutMaskingFeature(checked) {
    if ((!checked || !$printCutoutMaskingFeatureStore) && $mapViewStore) {
      $mapViewStore.graphics.removeAll();
    } else if (checked && $printCutoutMaskingFeatureStore && $mapViewStore) {
      $mapViewStore.graphics.removeAll();
      $mapViewStore.graphics.add($printCutoutMaskingFeatureStore);
    }
  }

  function cleanupAndClose() {
    cookieCutterChecked = false;
    close();
  }
</script>

<DialogOverlay {isOpen} onDismiss={cleanupAndClose} class="overlay">
  <DialogContent
    aria-label="Save Map as PDF"
    class="content dialog-box-content print"
  >
    <div class="dialog-title-wrapper">
      <h2>Save Map as PDF</h2>

      <!-- custom close button, wrapped up in a div with the h2 title text -->
      <button
        class="usa-button usa-button--unstyled custom-dialog-button"
        title="Close"
        on:click={cleanupAndClose}
      >
        <span class="esri-icon-close" style="vertical-align: top" />
      </button>
    </div>

    <form style="padding: 0px 24px 24px; overflow: auto;">
      <fieldset class="usa-fieldset" style="margin-top: 1.5rem;">
        <legend>Preserve map</legend>
        <div class="usa-radio">
          <input
            class="usa-radio__input"
            id="scalePreserved"
            type="radio"
            name="preserve"
            value={true}
            bind:group={scalePreserved}
            checked
          />
          <label class="usa-radio__label" for="scalePreserved"> scale </label>
        </div>
        <div class="usa-radio">
          <input
            class="usa-radio__input"
            id="preserveExtent"
            type="radio"
            name="preserve"
            value={false}
            bind:group={scalePreserved}
          />
          <label class="usa-radio__label" for="preserveExtent"> extent </label>
        </div>
      </fieldset>

      <label class="usa-label label-for-svelte-select" for="layout-options">
        Layout
      </label>
      <Select
        inputAttributes={{
          name: "layout-options",
          id: "layout-options",
        }}
        isSearchable={false}
        isClearable={false}
        listAutoWidth={false}
        items={layoutTemplateOptions}
        bind:selectedValue={layout}
      />

      <label class="usa-label" for="dpivalue-options">
        Print quality (dpi)
      </label>
      <p class="disclaimer">
        Increased DPI could affect app performance.
      </p>
      <Select
        inputAttributes={{ name: "dpivalue-options", id: "dpivalue-options" }}
        isSearchable={false}
        isClearable={false}
        items={appConfig.dpiOptions}
        bind:value={printQualityDpi}
      />

      <label class="usa-label label-for-svelte-select" for="projection-options">
        Projection
      </label>
      <Select
        inputAttributes={{
          name: "projection-options",
          id: "projection-options",
        }}
        isSearchable={false}
        isClearable={false}
        listAutoWidth={false}
        items={appConfig.exportProjectionOptions}
        bind:selectedValue={exportProjectionWKID}
      />

      <div class="usa-checkbox">
        <input
          class="usa-checkbox__input"
          id="check-cookie-cutter"
          type="checkbox"
          name="cookie-cutter"
          bind:checked={cookieCutterChecked}
          disabled={!$printCutoutMaskingFeatureStore}
        />
        <label
          class="usa-checkbox__label usa-label"
          style="margin-top: 1.5rem;"
          for="check-cookie-cutter"
        >
          Show only selected area
        </label>
      </div>

      <hr class="submenu-separator" />

      <label class="usa-label" for="title-input"> Title </label>
      <input
        class="usa-input"
        id="title-input"
        name="title-input"
        type="text"
        placeholder="Enter title"
        bind:value={title}
      />

      <label class="usa-label" for="produced-by-input"> Produced by </label>
      <input
        class="usa-input"
        id="produced-by-input"
        name="produced-by-input"
        type="text"
        placeholder="Enter name"
        bind:value={producedBy}
      />

      <label class="usa-label" for="description-input"> Description </label>
      <textarea
        class="usa-textarea"
        style="height: unset;"
        rows="3"
        id="description-input"
        name="description-input"
        type="text"
        placeholder="Enter description"
        bind:value={description}
      />

      <button
        class="usa-button width-full"
        style="text-align: center; margin-top: 1.5rem"
        on:click|preventDefault={() => {
          beginPrint(
            $mapViewStore,
            layout,
            printQualityDpi,
            exportProjectionWKID,
            scalePreserved,
            title,
            producedBy,
            description
          );
        }}
      >
        Export
      </button>

      <p class="disclaimer">
        The current map {scalePreserved ? "scale" : "extent"} and all layer contents
        will be included in the PDF.
      </p>
    </form>
  </DialogContent>
</DialogOverlay>

<style>
  /* https://github.com/reecelucas/svelte-accessible-dialog#styling */
  :global([data-svelte-dialog-overlay].overlay) {
    /* keep it above the <HeaderToolbar>, otherwise the dialog doesn't block those tools */
    z-index: 1100;
  }

  /* :global([data-svelte-dialog-content].content) {  } */

  /* CSS for custom design */
  :global(.content.dialog-box-content.print) {
    padding: 0rem !important;
    margin: 10vh auto !important;
    width: 480px !important;
    display: flex;
    flex-direction: column;
    height: calc(80vh);
  }

  /* to aid in aligning the title text and our custom close button */
  .dialog-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #005ea2;
  }

  .dialog-title-wrapper .custom-dialog-button {
    background-color: #005ea2;
    color: #fff;
    margin-right: 0px !important;
    padding: 18px 20px !important;
    z-index: 1;
  }

  .dialog-title-wrapper .custom-dialog-button:hover {
    background-color: #082e65 !important;
  }

  .dialog-title-wrapper h2 {
    color: #ffffff;
    margin: 16px;
    flex: 1;
    text-align: center;
    position: relative;
    left: 32px;
    font-size: 20px;
    line-height: 100%;
  }

  .disclaimer {
    margin-top: 16px;
    font-style: italic;
    color: #535353;
  }
</style>
