<script>
  import { cropsListStore, cropsSelectionStore } from "../stores";
  import Select from "svelte-select";
  import { tick } from "svelte";

  export let expanded;
  export let collapseAllCustomDropdowns;

  // this workaround helps to auto-clear the select component
  // where we await a tick and set `selectedValue` back to undefined
  // (we wanted unique behavior--select & add--that wasn't quite the out of the box multiSelect behavior)
  // https://github.com/rob-balfre/svelte-select/issues/97
  // https://github.com/rob-balfre/svelte-select/issues/211
  let selectedValue;
  async function handleSelect(e) {
    await tick();
    selectedValue = undefined;

    // do not re-add the same crop to the cropsSelectionStore
    if ($cropsSelectionStore.findIndex((c) => c.value == e.detail.value) > -1) {
      return;
    }

    // add a new, unique crop choice to the cropsSelectionStore
    cropsSelectionStore.update((s) => {
      const match = $cropsListStore.find((c) => c.value == e.detail.value);
      s.push(match);
      return s;
    });
  }
</script>

<!-- USWDS nav bar child drop down menu -->
<li class="usa-nav__primary-item collapsible">
  <button
    class="usa-accordion__button usa-current"
    aria-expanded={expanded}
    on:click={() => {
      if (expanded) {
        expanded = false;
      } else {
        collapseAllCustomDropdowns();
        expanded = true;
      }
    }}
  >
    <span>Filter Crops</span>
  </button>
  <form
    class="usa-form usa-nav__submenu esri-floating-box-shadow"
    hidden={!expanded}
  >
    <h4 class="submenu-header">
      Select crops or land cover categories to filter <abbr
        title="Cropland Data Layer"
      >
        CDL
      </abbr>
    </h4>

    <label class="usa-label label-for-svelte-select" for="crops-combobox">
      Crops and land covers
    </label>
    <Select
      inputAttributes={{ name: "crops-combobox", id: "crops-combobox" }}
      placeholder="Select categories"
      listAutoWidth={false}
      items={$cropsListStore.map((c) => ({
        value: c.value,
        label: `<span title="${c.displayText}"><span class="color-swatch" style="background-color: ${c.colorSwatch};"></span> ${c.displayText}</span>`,
      }))}
      bind:selectedValue
      on:select={handleSelect}
    />

    <!-- selected crops tags container -->
    <div>
      {#if $cropsSelectionStore.length}
        <p class="color-swatch-header">Categories currently viewable on the map:</p>

        {#each $cropsSelectionStore as { value, displayText, colorSwatch } (value)}
          <span class="usa-tag imagery-category-tag" title={displayText}>
            <span
              class="color-swatch"
              style="background-color: {colorSwatch};"
            />
            <span class="color-swatch-display-text">{displayText}</span>
            <button
              class="usa-button usa-button--unstyled margin-left-4 color-swatch-icon-button"
              style="padding: 0;"
              title="Close"
              on:click|preventDefault={() => {
                // remove just this single crop selection
                cropsSelectionStore.update((s) => {
                  const matchIndex = s.findIndex((c) => c.value == value);
                  s.splice(matchIndex, 1);
                  return s;
                });
              }}
            >
              <span
                class="esri-icon-close"
                style="vertical-align: middle; color: #959595; font-size: 12px;"
              />
            </button>
          </span>
        {/each}

        <button
          class="usa-button usa-button--unstyled"
          on:click|preventDefault={() => {
            // reset the cropsSelectionStore to an empty array
            cropsSelectionStore.set([]);
          }}
        >
          Clear all
        </button>
      {/if}
    </div>
  </form>
</li>

<style>
  .imagery-category-tag {
    text-transform: none; /* undo USWDS all caps */
    color: #1b1b1b;
    background-color: #f5f5f5;
    box-shadow: 0px 1px 2px 0px rgba(223, 223, 223, 0.6);
    display: inline-block;
    margin: 4px 12px;
  }

  .color-swatch-header {
    margin: 20px 0px 8px 0px;
  }

  :global(.color-swatch) {
    position: relative;
    top: -1px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    display: inline-block;
    border: 1px solid #1b1b1b;
    vertical-align: middle;
  }

  .color-swatch-display-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 125px;
    display: inline-block;
    vertical-align: bottom;
  }

  .color-swatch-icon-button {
    margin-left: 4px !important;
    width: auto !important;
  }
</style>
