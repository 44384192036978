<script>
  import { exportFeaturesStore, exportYearStore } from "../stores";
  import StatsDialog from "./analysisTools/StatsDialog.svelte";

  export let expanded;
  export let collapseAllCustomDropdowns;

  // disable the analysis buttons if there is no polygon AOI or year selection
  $: disabledAnalysisStatisticsButton =
    !$exportFeaturesStore || !$exportYearStore;
  $: analysisTooltip = !$exportFeaturesStore
    ? "Please select or define an area."
    : !$exportYearStore
    ? "Please choose a year."
    : "";

  // variables and functions to handle modal opening and closing
  let statsDialogOpen = false;
  function openStatsDialog() {
    statsDialogOpen = true;
  }
  function closeStatsDialog() {
    statsDialogOpen = false;
  }
</script>

<!-- USWDS nav bar child drop down menu -->
<!-- to get the child contents to be left-justified when opened, we have to apply `position: relative;` to the <li> and then `right: 0; position: absolute;` to the child <form> -->
<li
  class="usa-nav__primary-item collapsible desktop-view-title"
  style="margin-left: auto; position: relative"
>
  <button
    class="usa-accordion__button usa-current"
    aria-expanded={expanded}
    on:click={() => {
      if (expanded) {
        expanded = false;
      } else {
        collapseAllCustomDropdowns();
        expanded = true;
      }
    }}
  >
    <span>Analysis</span>
  </button>
  <form
    class="usa-form usa-nav__submenu esri-floating-box-shadow action-tool-dropdown"
    hidden={!expanded}
    style="right: 0; position: absolute"
  >
    <div class="usa-form-group" title={analysisTooltip}>
      <button
        class="usa-button usa-button--outline width-full"
        disabled={disabledAnalysisStatisticsButton}
        on:click|preventDefault={() => {
          openStatsDialog();
          expanded = false;
        }}
      >
        <span class="margin-right-1 esri-icon-chart custom-esri-button-icon" />
        Calculate area statistics
      </button>
    </div>
  </form>
</li>

<!-- dialog modal that can be opened by the analysis button -->
<StatsDialog isOpen={statsDialogOpen} close={closeStatsDialog} />

<style></style>
