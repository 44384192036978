<script>
  import { onMount, onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import { loadModules } from "esri-loader";
  import { exportYearStore } from "./stores";
  import { cleanupJsapiListener } from "./utils";
  import YearInput from "./yearTools/YearInput.svelte";

  // reference to an Esri JSAPI MapView instance
  export let mapView;
  // reference to an Esri JSAPI ImageryLayer instance
  export let timeEnabledImageryLayer;

  let esriSliderContainer;
  let esriSliderWidget;

  // boolean to help toggle showing the layer loading indicator
  let mapViewUpdating = true;

  let sliderThumbChangeListener;
  let sliderThumbDragListener;
  let imageryLayerViewUpdatingWatchers;

  let componentTitleMessage = "Loading Cropland Data Layer (CDL)";

  
  let animatorExpanded;
  let startYear;
  let endYear;
  let animatorYears = [];
  let animatorSecondsPerFrame = 1;
  let currentAnimatorIndex = 0;
  $: currentAnimatorYear = parseInt(animatorYears[currentAnimatorIndex])
  let animatorHandle;
  
  let yearOptions = []

  let swipeExpanded;
  let leftYear;
  let rightYear;
  let esriSwipeWidget;
  let esriSwipeContainer;
  let swipeEnabled = false;
  let swipeBusy = false;
  let imageryLayerLeft;
  let imageryLayerRight;

  $: toggleSwipe(swipeEnabled)
  const toggleSwipe = async (swipeEnabled) => {
    if (swipeEnabled) {
      await establishEsriSwipeWidget(
        mapView,
        timeEnabledImageryLayer,
        esriSwipeContainer
      );
    } else if (esriSwipeWidget) {
      destroyEsriSwipeWidget()
    }
  }

  onMount(async () => {
    try {
      // update the Boolean mapViewUpdating property by watching for changes
      // to an array of imagery layerViews' `updating` property
      // and checking for the status of that same property among all imagery layerViews
      mapView
        .whenLayerView(timeEnabledImageryLayer)
        .then((timeEnabledImageryLayerView) => {
          // in an array for now just in case we have more than 1 imageryLayerView in the future
          imageryLayerViewUpdatingWatchers = [timeEnabledImageryLayerView].map(
            (lv, idx, lvArray) => {
              lv.watch("updating", () => {
                mapViewUpdating = lvArray.some((otherLvs) => otherLvs.updating);
              });
            }
          );
        });

      // set up the time slider widget which depends on the imagery layer's time info
      await establishEsriTimeSliderWidget(
        mapView,
        timeEnabledImageryLayer,
        esriSliderContainer
      );

      componentTitleMessage =
        'Select&nbsp;<abbr title="Cropland Data Layer">CDL</abbr>&nbsp;year';
    } catch (error) {
      componentTitleMessage =
        '<span class="margin-right-1 esri-icon-notice-triangle"></span> An error occurred loading&nbsp;<abbr title="Cropland Data Layer">CDL</abbr>';
      mapViewUpdating = false;
      console.error(error);
    }
  });

  async function establishEsriSwipeWidget(
    mapView,
    timeEnabledImageryLayer,
    containerNode
  ) {
    const [Swipe, ImageryLayer] = await loadModules(["esri/widgets/Swipe", "esri/layers/ImageryLayer"]);

    swipeBusy = true;

    await timeEnabledImageryLayer.when();

    imageryLayerLeft = new ImageryLayer({
      url: timeEnabledImageryLayer.url,
      timeExtent: {
        start: new Date(leftYear, 0, 1),
        end: new Date(leftYear, 11, 31),
      },
      useViewTime: false,
      title: "Left Swipe CDL"
    })

    imageryLayerRight = new ImageryLayer({
      url: timeEnabledImageryLayer.url,
      timeExtent: {
        start: new Date(rightYear, 0, 1),
        end: new Date(rightYear, 11, 31),
      },
      useViewTime: false,
      title: "Right Swipe CDL"
    })

    mapView.map.add(imageryLayerLeft)
    mapView.map.add(imageryLayerRight)

    await imageryLayerLeft.when()
    await imageryLayerRight.when()

    esriSwipeWidget = new Swipe({
      view: mapView,
      leadingLayers: [imageryLayerLeft],
      trailingLayers: [imageryLayerRight],
      direction: "horizontal",
      position: 50
    })

    timeEnabledImageryLayer.opacity = 0.0;

    mapView.ui.add(esriSwipeWidget)

    swipeBusy = false;
  }

  function destroyEsriSwipeWidget() {
    mapView.map.remove(imageryLayerLeft);
    mapView.map.remove(imageryLayerRight);

    imageryLayerLeft = null;
    imageryLayerRight = null;

    timeEnabledImageryLayer.opacity = 1;

    esriSwipeWidget.destroy();
  }

  function restartSwipe () {
    console.log(swipeEnabled, esriSwipeWidget)
    if (swipeEnabled) {
      if (esriSwipeWidget) {
        destroyEsriSwipeWidget()
      }

      establishEsriSwipeWidget(
        mapView,
        timeEnabledImageryLayer,
        esriSwipeContainer
      )
    }
  }

  async function establishEsriTimeSliderWidget(
    mapView,
    timeEnabledImageryLayer,
    containerNode
  ) {
    const [Slider] = await loadModules(["esri/widgets/Slider"]);

    await timeEnabledImageryLayer.when();
    startYear =
      timeEnabledImageryLayer.timeInfo.fullTimeExtent.start.getUTCFullYear();
    endYear =
      timeEnabledImageryLayer.timeInfo.fullTimeExtent.end.getUTCFullYear();

    yearOptions = Array(endYear - startYear + 1).fill(startYear).map((x, y) => x + y)
    leftYear = parseInt(yearOptions[0])
    rightYear = parseInt(yearOptions[0])
    animatorYears = yearOptions

    // set initial timeExtent value and update the exportYearStore
    updateMapViewTimeExtentAndSelectYear(mapView, endYear);

    // create the custom time slider
    esriSliderWidget = new Slider({
      container: containerNode,
      min: startYear,
      max: endYear,
      values: [endYear], // default to most recent
      steps: 1,
      visibleElements: {
        rangeLabels: true,
        labels: true,
      },
    });

    // via keyboard change, also update the timeExtent value and update the exportYearStore
    sliderThumbChangeListener = esriSliderWidget.on("thumb-change", (e) => {
      updateMapViewTimeExtentAndSelectYear(mapView, e.value);
    });

    // via mouse change, also update the timeExtent value and update the exportYearStore
    sliderThumbDragListener = esriSliderWidget.on("thumb-drag", (e) => {
      if (e.state === "stop") {
        updateMapViewTimeExtentAndSelectYear(mapView, e.value);
      }
    });
  }

  function updateMapViewTimeExtentAndSelectYear(mapView, year) {
    // TODO: are we accounting for user's local timezones correctly vs. image server in UTC?
    mapView.timeExtent = {
      start: new Date(year, 0, 1),
      end: new Date(year, 11, 31),
    };

    exportYearStore.set(year);
  }

  onDestroy(() => {
    // remove Esri JSAPI listeners
    cleanupJsapiListener(sliderThumbChangeListener);
    cleanupJsapiListener(sliderThumbDragListener);
    imageryLayerViewUpdatingWatchers.forEach((w) => cleanupJsapiListener(w));
  });

  function startAnimation () {
    animatorHandle = setInterval(() => {
      esriSliderWidget.viewModel.setValue(0, currentAnimatorYear)
      updateMapViewTimeExtentAndSelectYear(mapView, currentAnimatorYear)
      currentAnimatorIndex += 1
      currentAnimatorIndex %= animatorYears.length
    }, animatorSecondsPerFrame * 1000)
  }

  function stopAnimation () {
    if (animatorHandle) {
      clearInterval(animatorHandle)
      animatorHandle = null
      currentAnimatorIndex = 0
    }
  }

  function restartAnimation () {
    if (animatorHandle) {
      clearInterval(animatorHandle)
      startAnimation()
    }
  }
</script>

<div
  class="slider-outer-container esri-widget esri-floating-box-shadow"
  class:custom-updating={mapViewUpdating}
  class:animator-expanded={animatorExpanded}
  class:swipe-expanded={swipeExpanded}
>
  <label 
    class="usa-label range-slider" 
    for="range-slider"
  >
    <span>{@html componentTitleMessage}
      {#if $exportYearStore && $exportYearStore < 2008}
      <span
        transition:fade={{ duration: 200 }}
        style="font-size: 0.85rem; font-style: italic; margin-left: 4px;"
      >
        (limited data coverage)
      </span>
      {/if}
    </span>

    {#if esriSliderWidget}
      <span>
        <button 
          class="animate-button esri-widget"
          on:click={() => {
            animatorExpanded = !animatorExpanded;
            swipeExpanded = false;
            swipeEnabled = false;
            stopAnimation()

            if (animatorExpanded && yearOptions.length > 0) {
              animatorYears = yearOptions
            }
          }}
        >
          <span>
            Animate
            {#if animatorExpanded}
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            {:else}
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
              </svg>
            {/if}
          </span>
        </button>

        <button 
          class="swipe-button esri-widget"
          on:click={() => {
            swipeExpanded = !swipeExpanded;
            animatorExpanded = false;
            swipeEnabled = false;
            stopAnimation()

            if (swipeExpanded && yearOptions.length > 0) {
              leftYear = parseInt(yearOptions[0])
              rightYear = parseInt(yearOptions[0])
            }
          }}
        >
          <span>
            Swipe
            {#if swipeExpanded}
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            {:else}
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
              </svg>
            {/if}
          </span>
        </button>
      </span>
    {/if}
  </label>

  <!-- <div
    id="swipe-container"
    class="swipe-inner-container"
    bind:this={esriSwipeContainer}
  /> -->

  {#if animatorExpanded}
    <div class="animator-container">
      <div class="animator-column space-between">
        <YearInput 
          label="Select one or multiple years to animate."
          selectStyle="width: 50%; flex-grow: 1;" 
          labelStyle="flex-grow: 1;"
          multiple 
          years={yearOptions} 
          onYearChange={(selectedYears) => {
          if (selectedYears.length === 0) {
            animatorYears = yearOptions
            return
          }

          animatorYears = selectedYears
          restartAnimation()
        }}
        />
      </div>
      <div class="animator-column">
        <label class="input-label align-right">
          Sec/Frame
          <input 
            class="seconds-input"
            type="number" 
            value={animatorSecondsPerFrame} 
            step={0.1}
            on:change={(obj) => {
              animatorSecondsPerFrame = obj.target.value
              restartAnimation()
            }}
          />
        </label>
        
        <button 
          on:click={() => {
            animatorExpanded = !animatorExpanded;
            if (animatorHandle) stopAnimation()
            else startAnimation()
          }} 
          class="swipe-button action-swipe-button"
        >
          <span>
            {animatorHandle ? "Stop" : "Start"}
            {#if animatorHandle}
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M18,18H6V6H18V18Z" />
              </svg>
            {:else}
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
              </svg>
            {/if}
          </span>
        </button>
      </div>
    </div>
  {/if}

  {#if swipeExpanded}
    <div class="swipe-container">
      <YearInput 
        label="Select left year." 
        years={yearOptions}
        onYearChange={(selectedYears) => {
          if (selectedYears.length === 0) leftYear = null
          else leftYear = parseInt(selectedYears[0])

          if (!leftYear || !rightYear || (leftYear === rightYear)) swipeEnabled = false;
          else restartSwipe()
        }}
      />
      <button 
        on:click={() => {
          swipeEnabled = !swipeEnabled;
        }} 
        disabled={swipeBusy || !leftYear || !rightYear || (leftYear === rightYear)}
        class="animate-button action-animate-button"
      >
        <span>
          {swipeEnabled ? "Stop" : "Start"}
          {#if swipeEnabled}
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18,18H6V6H18V18Z" />
            </svg>
          {:else}
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
            </svg>
          {/if}
        </span>
      </button>
      <YearInput 
        label="Select right year." 
        years={yearOptions}
        onYearChange={(selectedYears) => {
          if (selectedYears.length === 0) rightYear = null
          else rightYear = parseInt(selectedYears[0])

          if (!leftYear || !rightYear || (leftYear === rightYear)) swipeEnabled = false;
          else restartSwipe()
        }}
      />
    </div>
  {/if}

  <div
    id="range-slider"
    class="slider-inner-container"
    style={swipeExpanded ? "display: none;" : ""}
    bind:this={esriSliderContainer}
  />
</div>

<style>
  .slider-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    height: 100px;
    width: 460px;
    transition: height 250ms ease-in-out;
  }

  .slider-outer-container.animator-expanded {
    height: 260px;
  }
  
  .slider-outer-container.swipe-expanded {
    height: 120px;
  }

  .slider-outer-container label {
    font-size: 16px !important;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .slider-inner-container {
    width: 100%;
    height: auto;
    margin-top: 18px;
  }

  .range-slider {
    justify-content: space-between;
  }

  .animate-button,
  .swipe-button {
    cursor: pointer;
    background: white;
    border: none;
  }

  .animate-button span,
  .swipe-button span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .animator-container,
  .swipe-container {
    margin: 12px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .animator-column {
    display: flex;
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }

  .seconds-input {
    width: 80px;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .action-animate-button,
  .action-swipe-button {
    background: var(--calcite-ui-brand);
    color: white;
    height: 32px;
  }

  .action-animate-button:disabled,
  .action-swipe-button:disabled {
    background: lightgrey;
    cursor: not-allowed;
  }

  .input-label {
    display: flex;
    margin: 0 !important;
    flex-direction: column !important;
  }

  .align-right {
    align-items: flex-end !important;
  }
</style>
