import { writable, derived } from "svelte/store";
import { generatePrintCutoutMaskingFeature } from "./utils";

/*
STORES TO HELP WITH SHARING ACCESS TO JSAPI INSTANCES
*/

export const mapViewStore = writable(null);
export const imageryLayerStore = writable(null);
export const sketchViewModelStore = writable(null);

// Create a data store called current imagery layer that stores the user selected data layer, which drives the application. 
export const currentImageryLayer = writable({});

/*
STORES TO HELP WITH UI/UX FUNCTIONALITY
*/

// array of objects
// - each with a unique US state `value` property and `displayText` property
// - starts off as an empty array but is hydrated once during EsriMapView startup by querying the US states feature layer
export const statesListStore = writable([]);

// string
// - either empty or the currently chosen US state name
export const stateSelectionStore = writable(undefined);

// array of strings
// - each is a county name
// - can be an empty array, i.e. when `stateSelectionStore` is an empty string
//   because the user has not chosen a US state yet, or perhaps cleared their previous US state selection
// - is hydrated by querying the US counties feature layer when a US state is chosen
export const countiesListStore = writable([]);

// string
// - either empty or the currently chosen US county name
export const countySelectionStore = writable(undefined);

// array of objects
// - each with a unique NASS region value` property and `displayText` property
// - starts off as an empty array but is hydrated once during EsriMapView startup by querying the NASS regions feature layer
export const nassRegionsListStore = writable([]);

// array of strings
// - either empty or the currently chosen NASS Region
export const nassRegionSelectionStore = writable(undefined);

// array of objects
// - each with:
//   - properties to help with the the crop selection UI/UX: `value`, `displayText`, and `colorSwatch`
//   - property to help with pixelFilter masking: `rgb` (DEPRECATED)
// - starts off as an empty array but is hydrated once during EsriMapView startup by getting values from the imagery layer attribute table
export const allPixelCategoriesStore = writable([]);

// array of objects
// a "derived" svelte store, which filters objects from the `allPixelCategoriesStore` into a subset that have non-null `displayText` (Class_Name) and color `Opacity` greater than 0
// this serves to set the unique list of crop categories on the svelte store,
// which will in turn drive the UI/UX combobox and the imagery layer's renderer
export const cropsListStore = derived(
  allPixelCategoriesStore,
  ($allPixelCategoriesStore) =>
    $allPixelCategoriesStore.filter(
      (pixelCategoryInfo) =>
        pixelCategoryInfo.displayText &&
        pixelCategoryInfo.serviceAttributes.Opacity > 0
    )
);

// array of objects
// - either empty or currently chosen crop categories
export const cropsSelectionStore = writable([]);

// array of objects
// - either `false` or an array of Esri Graphic instances
// - helper store which only serves to communicate newly generated features (from a shapefile upload) in the <HeaderToolbar> over to <EsriMapView>
export const uploadedFeaturesStore = writable([]);

/*
STORES TO HELP WITH EXPORTING AND ANALYSIS FUNCTIONALITY
*/

// selected export features
// - either `false` or an array of Esri Graphic instances
export const exportFeaturesStore = writable(false);

// cutout masking feature (aka: "cookie cutter graphic") used by an optional PDF printout generation setting
// a "derived" svelte store
// - either `false` or an Esri Graphic instance
export const printCutoutMaskingFeatureStore = derived(
  exportFeaturesStore,
  ($exportFeaturesStore, set) => {
    generatePrintCutoutMaskingFeature($exportFeaturesStore).then(
      (printCutoutMaskingFeature) => set(printCutoutMaskingFeature)
    );
  }
);

// selected export year
// - either `false` or an integer, e.g. 2019
export const exportYearStore = writable(2020);

// selected export crop types
// a "derived" svelte store, which converts objects from the `cropsSelectionStore` into a simple array of crop type strings
export const exportCropTypesStore = derived(
  cropsSelectionStore,
  ($cropsSelectionStore) => $cropsSelectionStore.map((c) => c.displayText)
);

export const layerListStore = writable([])

// string
// - either empty or the currently chosen layer name
export const layerSelectionStore = writable("Cropland Data Layer (CDL)");

// string
// - either other or the CDL depending on the chosen layer name
export const appMode = writable("CDL");

// string
// - dataset param for calculate statistics GP Service
export const datasetParam = writable("CDLS_WM");
