<script>
  import {
    statesListStore,
    stateSelectionStore,
    countiesListStore,
    countySelectionStore,
    nassRegionsListStore,
    nassRegionSelectionStore,
  } from "../stores";
  import Select from "svelte-select";

  export let expanded;
  export let collapseAllCustomDropdowns;
</script>

<!-- USWDS nav bar child drop down menu -->
<li class="usa-nav__primary-item collapsible">
  <!--
    To override the auto-closing behavior of the USWDS "dropdown accordion button" below:
    1. Remove the "aria-controls" prop of the <button>
    2. Remove the class "usa-nav__link" from the <button>
    3. Remove the "id" prop of the sibling <ul>
    4. Wire up our own boolean toggling logic for their "aria-expanded" and "hidden" props, respectively.
  -->
  <button
    class="usa-accordion__button usa-current"
    aria-expanded={expanded}
    on:click={() => {
      if (expanded) {
        expanded = false;
      } else {
        collapseAllCustomDropdowns();
        expanded = true;
      }
    }}
  >
    <span>Select Area</span>
  </button>
  <form
    class="usa-form usa-nav__submenu esri-floating-box-shadow"
    hidden={!expanded}
  >
    <h4 class="submenu-header">Select area of interest</h4>

    <label class="usa-label label-for-svelte-select" for="state-combobox">
      State
    </label>
    <Select
      inputAttributes={{ name: "state-combobox", id: "state-combobox" }}
      placeholder="Select a state"
      listAutoWidth={false}
      items={$statesListStore.map((state) => ({
        value: state.value,
        label: `<span title="${state.displayText}">${state.displayText}</span>`,
      }))}
      bind:selectedValue={$stateSelectionStore}
    />

    <label class="usa-label label-for-svelte-select" for="county-combobox">
      County
    </label>
    <Select
      inputAttributes={{ name: "county-combobox", id: "county-combobox" }}
      placeholder="Select a county"
      listAutoWidth={false}
      items={$countiesListStore.map((countyName) => ({
        value: countyName,
        label: countyName,
        label: `<span title="${countyName}">${countyName}</span>`,
      }))}
      bind:selectedValue={$countySelectionStore}
    />

    <hr class="submenu-separator" />

    <label class="usa-label label-for-svelte-select" for="nass-region-combobox">
      NASS Region
    </label>
    <Select
      inputAttributes={{
        name: "nass-region-combobox",
        id: "nass-region-combobox",
      }}
      listAutoWidth={false}
      placeholder="Select a NASS Region"
      items={$nassRegionsListStore.map((nassRegion) => ({
        value: nassRegion.value,
        label: `<span title="${nassRegion.displayText}">${nassRegion.displayText}</span>`,
      }))}
      bind:selectedValue={$nassRegionSelectionStore}
    />
  </form>
</li>

<style>
  .first-menu-item {
    margin-top: 0px;
    margin-left: 24px;
    --itemHoverBG: #e1eff9;
    --itemIsActiveBG: #005ea2;
    --border: 1px solid #565c65;
    --borderRadius: 0px;
  }
</style>
